import React, { useContext, useEffect, useState } from 'react';
import { ComponentProps } from 'lib/component-props';
import { PSP } from 'models/PetSuppliesPlus.Model';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { useOcDispatch, useOcSelector } from 'src/redux/ocStore';
import ImageHelper from 'components/helpers/ImageHelper';
import { currencyUsdFormatter } from 'lib/utils/formatters';
import TextHelper from 'components/helpers/TextHelper';
import IconHelper from 'components/helpers/IconHelper';
import { BuyerProductWithXp, LineItemWithXp } from 'src/redux/xp';
import useOcCart from 'src/hooks/useOcCart';
import { calculateOrder, removeLineItem, updateLineItem } from 'src/redux/ocCurrentOrder';
// import { PayloadAction } from '@reduxjs/toolkit';
// import { InventoryRecord, InventoryRecords } from 'ordercloud-javascript-sdk';
import { tv } from 'tailwind-variants';
import useDictionary from 'src/hooks/useDictionary';
import { isProductInWishlist, updateProductInWishlist } from 'src/redux/wishlist';
import ComponentContext from 'lib/context/ComponentContext';
import clsx from 'clsx';
import { UILineItemPrice, useLineItemPriceForUI } from 'src/hooks/useLineItemPriceForUI';
import { getGTMSessionStorage, sendProductsPromotion } from 'src/utils/sendGTMEvent';
import {
  FulfillmentType,
  GTMLabels,
  GTM_EVENT,
  getProductDetailPath,
  getProductPromoTag,
  getTruncatedProductPromoTag,
} from 'components/helpers/Constants';
import { ProductSearchResultModelWithVariants } from 'src/helpers/search/SearchResults/types';
import Link from 'next/link';
import { LineItemProduct, OrderPromotion } from 'ordercloud-javascript-sdk';
import { pDPComponentVariants } from 'components/ProductDetail/ProductDetail';
import { getFirstProductImage } from 'src/helpers/productDetailHelper';
import { myFavoriteVariants } from 'components/MyFavorite/myFavoriteVariants';

import Loader from 'components/Loader/Loader';
export type CartItemListingProps = ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.CartItemListing.Fields.CartItemListing;
const cartItemListingVariant = tv(
  {
    slots: {
      base: ['cartItemListing'],
      headingWrapper: ['py-desk-padding-micro-x', 'bg-color-background-light'],
      headingTitle: [
        'text-heading-desk-small-bold',
        'font-heading-desk-small-bold',
        'leading-heading-desk-small-bold',
      ],
      productList: ['productList', 'rounded-[8px]', 'bg-color-background-white'],
      productCard: ['relative'],
      productWrpper: ['relative', 'border-b-[1px]', 'overflow-hidden', 'border-color-border-mid'],
      imageWrapper: [
        'h-auto',
        'w-[144px]',
        'min-w-[144px]',
        'relative',
        'pl-mob-padding-tight-x',
        'lg:pl-desk-padding-tight-x',
        'flex',
        'justify-center',
      ],
      img: ['w-auto', 'min-h-[104px]', 'h-[104px]'],
      retailMeasureWrapper: [
        'absolute',
        'bottom-0',
        'right-[-20%]',
        'p-desk-global-spacing-spacing-05',
        'flex',
      ],
      retailMeasure: [
        'bg-color-brand-primary-1-base',
        'px-desk-global-spacing-spacing-1',
        'text-body-small-bold',
        'font-body-small-bold',
        'leading-body-small-bold',
        'relative',
        'text-white',
      ],
      priceWrapper: [''],
      leftContent: [''],
      rightContent: [''],
      productDetail: [''],
      h3: [
        'text-body-large-bold',
        'no-underline',
        'hover:no-underline',
        'font-body-large-bold',
        'leading-body-large-bold',
      ],
      listTotal: ['w-fit'],
      memberTotal: ['w-fit'],
      favoriteIcon: [''],
      quantityErrorWrapper: [''],
      quantityWrapper: [
        'w-fit',
        'border-[1px]',
        'border-color-border-mid',
        'rounded-[4px]',
        'items-center',
        'text-heading-desk-large-regular',
        'font-heading-desk-large-regular',
        'leading-heading-desk-large-regular',
      ],
      quantityInputWrapper: ['px-[15.5px]', 'max-w-[99px]', 'gap-[10px]', 'items-center'],
      quantityInput: [
        'text-heading-desk-large-regular font-heading-desk-large-regular leading-heading-desk-large-regular',
        'w-fit',
        'outline-0',
        'max-w-[22px]',
      ],
      quantityMainWrapper: [''],
      quantityButtonWrapper: [
        'flex-col',
        'bg-color-background-light',
        // 'px-[11px]',
        // 'py-3',
        'w-[31px] h-[60px]',
        'rounded-sm',
      ],
      productQtyButton: ['w-full h-1/2 text-[17px] font-[700]'],
      removeWrapper: ['mt-[20px] lg:mt-desk-margin-loose-right'],
      removeLink: ['gap-desk-space-between-base-vertical', 'flex-col'],
      productTitleWrapper: ['gap-[53px]'],
      cartNumberWrapper: ['flex', 'items-center'],
      colSpan4: ['col-span-4'],
      cartText: [
        'font-heading-desk-xLarge-bold',
        'font-primary',
        'leading-heading-desk-xLarge-bold',
        'text-heading-desk-xLarge-bold',
        'text-color-text-black',
      ],
      cartNumber: [
        'text-body-large-regular',
        'leading-body-large-regular',
        'font-body-large-regular',
        'flex',
        'gap-[3px]',
      ],
      heartIcon: [
        '[&>svg>*>*]:stroke-color-brand-primary-1-base [&>svg>*]:stroke-color-brand-primary-1-base [&>svg>*>*]:fill-color-brand-primary-1-base',
        '[&>svg]:min-w-[30px] ',
      ],
      qtyLabel: [
        'text-heading-desk-medium-regular font-heading-desk-medium-regular leading-heading-desk-medium-regular',
      ],
      retaiIcon: ['[&>svg>*]:fill-color-brand-primary-1-base'],
    },
    compoundSlots: [
      {
        slots: [
          'removeLink',
          'productWrpper',
          'productDetail',
          'quantityButtonWrapper',
          'quantityWrapper',
          'removeWrapper',
          'quantityMainWrapper',
          'productTitleWrapper',
          'quantityInputWrapper',
        ],
        class: ['flex'],
      },
    ],
    variants: {
      size: {
        mobile: {
          cartNumberWrapper: ['gap-mob-margin-base-right'],
          headingWrapper: ['hidden'],
          productCard: ['p-mob-padding-micro-y'],
          productWrpper: [
            'pr-mob-padding-micro-x',
            'py-mob-padding-micro-y',
            'gap-mob-margin-loose-bottom',
          ],
          listTotal: ['mt-mob-margin-loose-bottom'],
          memberTotal: ['mt-mob-space-between-base-vertical'],
          productDetail: ['flex-col'],
          quantityWrapper: ['mt-mob-margin-loose-bottom'],
          removeWrapper: ['items-end', 'justify-between'],
          removeLink: ['items-start'],
          quantityMainWrapper: ['flex-col', 'justify-between'],
        },
        desktop: {
          cartNumberWrapper: ['gap-desk-margin-base-right'],
          headingWrapper: ['grid', 'grid-cols-6'],
          productCard: ['p-desk-padding-micro-y'],
          productWrpper: [
            'pr-desk-padding-tight-x',
            'py-desk-padding-tight-y',
            'gap-desk-margin-loose-bottom',
          ],
          productDetail: ['flex-col'],
          priceWrapper: [
            'mt-desk-margin-loose-right',
            'flex',
            'gap-desk-global-spacing-spacing-12',
          ],
          listTotal: ['mt-0'],
          memberTotal: ['mt-0'],
          quantityWrapper: ['mt-0'],
          removeWrapper: ['mt-0', 'items-start', 'gap-0'],
          rightContent: ['flex', 'gap-desk-margin-loose-right'],
          removeLink: ['items-center', 'gap-0'],
          quantityMainWrapper: ['flex-col justify-between'],
        },
        desktopLarge: {
          removeWrapper: ['items-start', 'mt-0'],
          quantityMainWrapper: ['flex-row', 'gap-desk-margin-loose-right'],
        },
      },
    },
  },
  { responsiveVariants: ['lg', 'xl'] }
);
const CartItemListing: React.FC<CartItemListingProps> = ({ fields }) => {
  const { getProductLineItems, getTipLineItem } = useOcCart();
  const productlineitems: LineItemWithXp[] = getProductLineItems();
  const promotions = useOcSelector((state) => state?.ocCurrentOrder?.orderPromotions);
  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);
  const [isDFS, setIsDFS] = useState<boolean>();
  const [lineItemPrice, setLineItemPrice] = useState<UILineItemPrice[]>();
  const { base, headingWrapper, headingTitle, productList, colSpan4 } = cartItemListingVariant({
    size: { initial: 'mobile', lg: 'desktop' },
  });

  // Function to calculate the price for each line item

  useEffect(() => {
    if (document.body.classList.contains('overflow-hidden')) {
      document.body.classList.remove('overflow-hidden');
    }
    if (componentContextData.openMiniCart) {
      setcomponentContextData({
        ...componentContextData,
        openMiniCart: false,
      });
    }
  }, []);

  //checking Order is Delivery or Pickup
  const cart = useOcSelector((state) => state?.ocCurrentOrder?.order);
  const tiplineitem = getTipLineItem() as LineItemWithXp;
  const selectedStore = useOcSelector((state) => state?.storeReducer?.selectedStore);

  // Sending GTM promotion data
  useEffect(() => {
    const newMethod = localStorage.getItem('selected_fulfillment_Method') || cart?.xp?.Fulfillment;
    setIsDFS(newMethod === FulfillmentType.DFS);
  }, [cart?.xp?.Fulfillment, componentContextData?.isDFSMethodChanged]);

  const { position } = getGTMSessionStorage();

  const totalCount = isDFS
    ? tiplineitem?.xp?.TipAmount &&
      cart?.Subtotal &&
      Number((cart?.Subtotal - tiplineitem?.xp?.TipAmount)?.toFixed(2))
    : Number(cart?.Subtotal?.toFixed(2));

  useEffect(() => {
    const productData = productlineitems?.map((lineItem) => {
      return {
        ...lineItem?.Product,
        quantity: lineItem?.Quantity,
        BasePrice: lineItem?.UnitPrice,
        listPrice: lineItem?.UnitPrice,
      };
    });
    const checkGtmLoad = () => {
      if (window) {
        window?.sessionStorage?.setItem(GTMLabels?.currentPage, '');
        window?.sessionStorage?.setItem(GTMLabels?.pageItem, '');
      }
      const newMethod =
        localStorage.getItem('selected_fulfillment_Method') || cart?.xp?.Fulfillment;
      const isEventFired =
        window &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any)['dataLayer']?.filter((val: any) => val?.event === GTM_EVENT?.viewCart)
          ?.length > 0;
      const isGTMLoad =
        //eslint-disable-next-line @typescript-eslint/no-explicit-any
        typeof window !== 'undefined' && (window as any)['google_tag_manager']?.dataLayer?.gtmLoad;
      if (!isEventFired && productData && productData?.length > 0 && isGTMLoad) {
        sendProductsPromotion({
          eventName: GTM_EVENT?.viewCart,
          data: productData as ProductSearchResultModelWithVariants[],
          position: position ?? 0,
          storeId: selectedStore?.storeId,
          currency: true,
          totalCount: totalCount,
          fulfillment_option: newMethod === FulfillmentType.DFS ? GTMLabels?.DFS : GTMLabels?.BOPIS,
        });
      } else {
        setTimeout(() => {
          checkGtmLoad();
        }, 1000);
      }
    };
    checkGtmLoad();
  }, [productlineitems]);

  //----------------------
  return (
    <div className={base()} data-component="components/Cart/CartItemListing">
      <div className={headingWrapper()}>
        <div className={colSpan4()}>
          <p aria-level={2} className={headingTitle()}>
            <Text field={fields?.itemLabel} />
          </p>
        </div>
        <div>
          <p aria-level={2} className={headingTitle({ className: 'ml-[-10px]' })}>
            <Text field={fields?.quantityLabel} />
          </p>
        </div>
        <div>
          <p aria-level={2} className={headingTitle({ className: 'text-center' })}>
            <Text field={fields?.subtotalLabel} />
          </p>
        </div>
      </div>
      {/* <pre className="hidden">{JSON.stringify(lineItems, null, 2)}</pre> */}
      <ul className={productList()}>
        {productlineitems?.map((line, index) => (
          <CartItem
            key={line.ID}
            fields={fields}
            lineItem={line}
            promotions={promotions ?? []}
            index={index}
            isDFS={isDFS || false}
            setLineItemPrice={setLineItemPrice}
            lineItemPrice={lineItemPrice}
          />
        ))}
      </ul>
    </div>
  );
};

const CartItem: React.FC<
  PSP.Sitecore.templates.PetSuppliesPlus.CartItemListing.Fields.CartItemListing & {
    lineItem: LineItemWithXp;
    promotions: OrderPromotion[];
    index: number;
    isDFS: boolean;
    setLineItemPrice: React.Dispatch<React.SetStateAction<UILineItemPrice[]>>;
    lineItemPrice: UILineItemPrice[] | undefined;
  }
> = ({ fields, promotions, lineItem, index, isDFS }) => {
  const {
    productCard,
    favoriteIcon,
    leftContent,
    rightContent,
    h3,
    imageWrapper,
    img,
    listTotal,
    memberTotal,
    priceWrapper,
    productWrpper,
    productTitleWrapper,
    productDetail,
    retailMeasure,
    quantityWrapper,
    quantityInput,
    retailMeasureWrapper,
    quantityErrorWrapper,
    quantityButtonWrapper,
    productQtyButton,
    removeWrapper,
    removeLink,
    quantityMainWrapper,
    quantityInputWrapper,
    heartIcon,
    qtyLabel,
    retaiIcon,
  } = cartItemListingVariant({
    size: { initial: 'mobile', lg: 'desktop', xl: 'desktopLarge' },
  });
  const { offerLabel } = myFavoriteVariants({
    size: { initial: 'mobile', lg: 'desktop' },
  });
  const {
    topTooltipWrapper,
    tooltipContainer,
    topToolTipDescription,
    topTooltipArrowFirstDiv,
    topTooltipArrowSecondDiv,
  } = pDPComponentVariants({
    size: { initial: 'mobile', lg: 'desktop' },
  });
  const dispatch = useOcDispatch();
  const [error, setError] = useState<boolean>(false);
  // used for force re-render of input
  // Originally we just used the quantity, but when there's an error that prevents
  // the quantity from updating (e.g. not enough inventory),
  // the value will stay the same and so input will not rerender
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const myStoreData = useOcSelector((state: any) => state?.storeReducer?.selectedStore);
  const [inputKey, setInputKey] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [lineItemQuantity, setLineItemQuantity] = useState<any>(lineItem?.Quantity ?? '');
  const { getDictionaryValue } = useDictionary();
  const setQuantity = async (quantity: number, li: LineItemWithXp) => {
    // If we're making a change to quantity, rerender the input

    setInputKey(inputKey + 1);
    setIsLoading(true);
    if (quantity <= 0 && lineItem.ID) {
      setIsLoading(false);
      return await removeLine();
    } else {
      const updatedLineItem = {
        ...lineItem,
        InventoryRecordID: li?.InventoryRecordID,
        Quantity: quantity,
      };

      const result = await dispatch(updateLineItem(updatedLineItem));

      if (
        result &&
        typeof result === 'object' &&
        'error' in result &&
        result?.error?.message?.includes('Insufficient inventory')
      ) {
        setError(true);
        li?.Quantity && setLineItemQuantity(li?.Quantity);
      } else {
        setError(false);
        setLineItemQuantity(result?.meta?.arg?.Quantity ?? 0);
      }
      setIsLoading(false);
      return result;
    }
  };
  const isAnonymous = useOcSelector((state) => state.ocAuth.isAnonymous);
  const wishlistData = useOcSelector((state) => state?.wishlist?.wishlist) ?? [];

  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);
  const addToFavoriteClick = (product: LineItemProduct, index: number) => {
    const productId = product?.ID;
    if (productId) {
      if (isAnonymous) {
        setcomponentContextData({
          ...componentContextData,
          ToggleLoginOverlayForm: true,
          productToAddInWishlistAfterLogin: product,
          productIdToAddInWishlistAfterLogin: productId,
          clickFrom: GTMLabels?.cart,
        });
        // router.push(`${urlConfig?.loginWithReturnUrl}${router.asPath}&favoritesku=${productId}`);
      } else {
        dispatch(
          updateProductInWishlist({
            product: product,
            index: index,
            storeId: myStoreData?.storeId,
            clickFrom: GTMLabels?.cart,
          })
        );
      }
    }
  };
  const removeLine = async () => {
    if (lineItem.ID) {
      await dispatch(removeLineItem(lineItem.ID)).then(() => {
        dispatch(calculateOrder());
      });
    }
  };

  const productUrl = getProductDetailPath(lineItem?.Product as BuyerProductWithXp);
  const [showOfferTooltip, setShowOfferTooltip] = useState<boolean | string>();
  const maxLengthForPromoTag =
    Number(useOcSelector((state) => state?.otherSettingSlice?.data?.maxLengthForPromoTag?.value)) ||
    0;

  const priceforShoppingcart: UILineItemPrice = useLineItemPriceForUI(lineItem, promotions ?? []);

  const { currentPage, pageItem } = getGTMSessionStorage();
  const promoTag =
    lineItem?.Product?.xp?.PromoTag &&
    getProductPromoTag(lineItem?.Product?.xp?.PromoTag, myStoreData?.dataareaid);
  return (
    <li key={lineItem.ID} className={productCard()}>
      {showOfferTooltip === lineItem.ID && (
        <div
          className={topTooltipWrapper({
            className: '!bottom-[88%] !left-[22%] lg:!bottom-[70%] lg:!left-[12%] -translate-x-1/2',
          })}
        >
          <div className={tooltipContainer()}>
            <div className={topTooltipArrowFirstDiv()}></div>
            <div className={topTooltipArrowSecondDiv()}></div>
            <p className={topToolTipDescription({ className: 'text-center' })}>{promoTag}</p>
          </div>
        </div>
      )}
      <div className={productWrpper()}>
        <div className={leftContent({ className: 'relative max-h-[145px] overflow-y-clip' })}>
          {promoTag && (
            <div
              className={offerLabel()}
              onMouseEnter={() => {
                if (maxLengthForPromoTag && promoTag && promoTag?.length > maxLengthForPromoTag) {
                  setShowOfferTooltip(lineItem.ID);
                }
              }}
              onMouseLeave={() => {
                if (maxLengthForPromoTag && promoTag && promoTag?.length > maxLengthForPromoTag) {
                  setShowOfferTooltip(false);
                }
              }}
            >
              {getTruncatedProductPromoTag(promoTag, maxLengthForPromoTag)}
            </div>
          )}
          <div className={imageWrapper()}>
            <Link className="relative" aria-label="product name" href={productUrl} prefetch={false}>
              <div className={retailMeasureWrapper()}>
                <div className={retailMeasure()}>
                  {lineItem.Product?.xp?.RetailUnit}&nbsp;
                  {lineItem.Product?.xp?.RetailMeasure}
                </div>
                <IconHelper icon="vector" className={retaiIcon()} />
              </div>
              {lineItem.Product?.xp?.Images ? (
                <ImageHelper
                  className={img()}
                  field={{
                    value: {
                      alt: lineItem.Product?.Name,
                      src: getFirstProductImage(lineItem.Product?.xp?.Images)?.Url,
                      width: 104,
                      height: 104,
                    },
                  }}
                />
              ) : null}
            </Link>
          </div>
        </div>
        <div className={rightContent()}>
          <div className={productDetail()}>
            <div className={productTitleWrapper()}>
              <Link aria-label="product name" href={productUrl} prefetch={false}>
                <h3 className={h3()}>{lineItem.Product?.Name}</h3>
              </Link>
              <div className={favoriteIcon({ className: 'hidden lg:block' })}>
                <button
                  onClick={() => lineItem.Product && addToFavoriteClick(lineItem.Product, index)}
                  aria-label="add to favorites"
                >
                  <IconHelper
                    className={heartIcon()}
                    icon={
                      !isAnonymous &&
                      lineItem.Product?.ID &&
                      isProductInWishlist(wishlistData, lineItem.Product?.ID)
                        ? 'heart'
                        : 'heart-stroke'
                    }
                  />
                </button>
              </div>
            </div>

            <div className={priceWrapper()}>
              {/* List Price */}
              {priceforShoppingcart?.lineItemMemberPrice ? (
                <>
                  <div className={listTotal()}>
                    <TextHelper
                      field={fields?.listTotal}
                      tag="p"
                      className={h3({ className: 'w-max' })}
                    />
                    <span className={h3({ className: !isAnonymous && 'line-through' })}>
                      {currencyUsdFormatter.format(priceforShoppingcart?.lineItemListPrice ?? 0)}
                    </span>
                  </div>
                  <div className={memberTotal()}>
                    <TextHelper
                      field={fields?.memberTotal}
                      tag="p"
                      className={h3({ className: 'w-max' })}
                    />
                    <span
                      className={h3({
                        className: clsx('text-system-red', isAnonymous && 'line-through'),
                      })}
                    >
                      {currencyUsdFormatter.format(priceforShoppingcart?.lineItemMemberPrice ?? 0)}
                    </span>
                  </div>
                </>
              ) : (
                <div className={listTotal()}>
                  <TextHelper
                    field={fields?.listTotal}
                    tag="p"
                    className={h3({ className: 'w-max' })}
                  />
                  <span className={h3({ className: '' })}>
                    {currencyUsdFormatter.format(priceforShoppingcart?.lineItemListPrice ?? 0)}
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className={quantityMainWrapper()}>
            <div className={quantityErrorWrapper()}>
              {error && (
                <p className={'text-system-red'}>{getDictionaryValue('OutOfStockMessage')}</p>
              )}

              <div className={quantityWrapper()}>
                <div className={quantityInputWrapper()}>
                  <TextHelper field={fields?.quantity} tag={'label'} className={qtyLabel()} />
                  {isLoading && <Loader />}
                  {/* {!isLoading && ( */}
                  <input
                    aria-label="quantity"
                    // Semi-hack to force input to rerender when quantity changes
                    // This allows us to have a psuedo-controlled input without
                    // needing to have an intermediary state
                    key={inputKey}
                    value={lineItemQuantity}
                    type="text"
                    inputMode="numeric"
                    onChange={(e) => {
                      const quantity = e.target?.value;
                      if (quantity === '') {
                        setLineItemQuantity(''); // Keep it as a number, but display as empty
                      } else if (Number(quantity) > 0) {
                        setLineItemQuantity(quantity);
                      } else if (lineItemQuantity === 0) {
                        setLineItemQuantity(1);
                      } else {
                        setLineItemQuantity(Number(quantity)); // Convert to a number if not empty
                      }
                    }}
                    onBlur={() => {
                      if (Number(lineItemQuantity) > 0) {
                        setLineItemQuantity(lineItemQuantity);
                        setQuantity(lineItemQuantity, lineItem);
                      } else if (lineItemQuantity === 0 || lineItemQuantity === '') {
                        setLineItemQuantity(1);
                        setQuantity(1, lineItem);
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        // Reset to 1 if empty or 0 on Enter key press
                        if (lineItemQuantity === 0 || lineItemQuantity === '') {
                          setLineItemQuantity(1);
                          setQuantity(1, lineItem);
                        } else {
                          setQuantity(lineItemQuantity, lineItem);
                        }
                      }
                    }}
                    className={quantityInput()}
                  />
                  {/* )} */}
                </div>
                <div className={quantityButtonWrapper()}>
                  <button
                    aria-label="add to cart"
                    className={productQtyButton()}
                    onClick={async (e) => {
                      e.preventDefault();
                      const quantityInc = parseInt(lineItemQuantity, 10) + 1;
                      await setQuantity(quantityInc, lineItem);
                      sendProductsPromotion({
                        eventName: GTM_EVENT?.addToCart,
                        data: lineItem?.Product as ProductSearchResultModelWithVariants,
                        currentPage: currentPage,
                        pageItem: pageItem,
                        position: index ?? 0,
                        click_from: GTMLabels?.fromPlus,
                        quantity: quantityInc,
                        parentId: lineItem?.Product?.ParentID,
                        listPrice: lineItem?.UnitPrice,
                        fulfillment_option: isDFS ? GTMLabels?.DFS : GTMLabels?.BOPIS,
                      });
                    }}
                  >
                    +
                  </button>
                  <button
                    aria-label="remove from cart"
                    className={productQtyButton()}
                    onClick={async (e) => {
                      e.preventDefault();
                      const quantityDec = parseInt(lineItemQuantity, 10) - 1;
                      await setQuantity(quantityDec, lineItem);
                      sendProductsPromotion({
                        eventName: GTM_EVENT?.removeFromCart,
                        data: lineItem?.Product as ProductSearchResultModelWithVariants,
                        currentPage: currentPage,
                        pageItem: pageItem,
                        position: index ?? 0,
                        click_from: GTMLabels?.fromMinus,
                        quantity: quantityDec,
                        parentId: lineItem?.Product?.ParentID,
                        listPrice: lineItem?.UnitPrice,
                        fulfillment_option: isDFS ? GTMLabels?.DFS : GTMLabels?.BOPIS,
                      });
                    }}
                  >
                    -
                  </button>
                </div>
              </div>
            </div>
            <div className={removeWrapper()}>
              <div className={removeLink()}>
                <p className={h3({ className: 'text-system-red  lg:block hidden' })}>
                  <span>{currencyUsdFormatter.format(lineItem.LineTotal ?? 0)}</span>
                </p>
                <button
                  aria-label="remove from cart"
                  onClick={async (e) => {
                    e.preventDefault();
                    await removeLine();
                    sendProductsPromotion({
                      eventName: GTM_EVENT?.removeFromCart,
                      data: lineItem?.Product as ProductSearchResultModelWithVariants,
                      currentPage: currentPage,
                      pageItem: pageItem,
                      position: index ?? 0,
                      click_from: GTMLabels?.fromCartRemove,
                      quantity: lineItemQuantity,
                      parentId: lineItem?.Product?.ParentID,
                      listPrice: lineItem?.UnitPrice,
                      fulfillment_option: isDFS ? GTMLabels?.DFS : GTMLabels?.BOPIS,
                    });
                  }}
                >
                  <TextHelper
                    field={fields?.remove}
                    tag="p"
                    className={h3({ className: 'underline' })}
                  />
                </button>
              </div>
              <div className={favoriteIcon({ className: 'lg:hidden' })}>
                <button
                  onClick={() => lineItem.Product && addToFavoriteClick(lineItem.Product, index)}
                  aria-label="add to cart"
                >
                  <IconHelper
                    className={heartIcon()}
                    icon={
                      !isAnonymous &&
                      lineItem.Product?.ID &&
                      isProductInWishlist(wishlistData, lineItem.Product?.ID)
                        ? 'heart'
                        : 'heart-stroke'
                    }
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export const Default = (props: CartItemListingProps): JSX.Element => {
  return <CartItemListing {...props} />;
};
